import React from 'react'
import "./HomeSection.css"

const HomeSection = ({ data, index }) => {
    return (
        <div className='HomeSection' style={{ backgroundColor: data?.bg }}>
            <div className='row'>
                <div className={`col-12 col-md-6 order-0 ${data?.imagePosition == "left" ? "order-md-1" : "order-md-0"} align-self-center`}>
                    <h2>{data?.title}</h2>
                    <p>{data?.description}</p>
                </div>
                <div className={`col-12 col-md-6 order-1 rightSection ${(index === 1 || index === 2) && "dogcat"} ${data?.imagePosition == "left" ? "order-md-0" : "order-md-1 rightImage"} align-self-center`}>
                    <img src={data?.image} alt={data?.title} loading="lazy" />
                </div>
            </div>
        </div>
    )
}

export default HomeSection
