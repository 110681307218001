import React from "react";
import { Header } from "../../components/Header/Header";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
    return (
        <div className="TermsAndConditions">
            <Header text="Términos y Condiciones" />
            <main>
                <h3>Términos y Condiciones</h3>
                <br />
                <p>Actualizado a fecha 08/01/2024</p>
                <br />
                <br />
                <p>Le damos la bienvenida al sitio web de Balvora («Sitio web»), un sitio web operado por Nebula Nexus LLC. Somos una empresa registrada en USA.
                    <br />
                    <br />
                    Revise los siguientes términos y condiciones de uso del Sitio web. Al acceder a cualquier información o material del Sitio web, o al utilizar o descargar dicha información o material, usted acepta respetar los términos y condiciones que hay a continuación (los «Términos de uso»). Si no está de acuerdo con estos Términos de uso, no utilice ni acceda a este Sitio web (ni a ningún material que se encuentre en él).
                    <br />
                    <br />
                    Es importante que tenga en cuenta que al utilizar determinadas secciones de este Sitio web, dicho uso estará sujeto a otros avisos de propiedad e información de derechos de autor. De ser así, se le pedirá que lea y confirme su aceptación de tales avisos antes de acceder a la sección correspondiente.
                    <br />
                    <br />
                    Tenga en cuenta que, cada cierto tiempo, es posible que cambiemos estos Términos de uso. Si lo hacemos, publicaremos los cambios en el Sitio web, por tanto, revise periódicamente el Sitio web si desea conocer dichos cambios. Si continúa utilizando el Sitio web después de haber realizado los cambios, se considerará que ha aceptado dichas modificaciones de los Términos de uso. La fecha que aparece en la parte superior de esta página web indica la fecha de última actualización de los Términos de uso.
                </p>

                <h3>1. Exención de responsabilidad médica</h3>
                <p>Toda la información contenida en este Sitio web, así como los productos y tratamientos, es meramente para fines informativos. Esta información no se debe considerar completa y no está dirigida a sustituir las visitas, llamadas o consultas ni el asesoramiento del profesional médico.
                    <br />
                    <br />
                    La información obtenida a través del uso del Sitio web no es exhaustiva y no abarca todas las dolencias, estados físicos ni tratamientos. La información publicada tiene únicamente fines informativos y debe ser utilizada bajo su propia responsabilidad.
                    <br />
                    <br />
                    Nunca debe ignorar la prestación de asesoramiento médico ni demorar el asesoramiento médico debido a algo que haya leído en este Sitio web, y no debe utilizar la información contenida en este Sitio web para diagnosticar un problema dental o de salud, ni para recetar medicamentos.

                </p>
                <h3>2. Uso del contenido del Sitio web</h3>
                <p>Los derechos de propiedad intelectual del Sitio web (incluidas, por ejemplo, nuestras marcas comerciales) y los derechos de autor de todo el material almacenado, mostrado y accesible en el Sitio web son de nuestra propiedad o de terceros («Contenido»). Todos estos derechos están reservados por nosotros o nuestros licenciantes.
                    <br />
                    <br />
                    No se le permite usar, modificar, copiar, reproducir, volver a publicar, cargar, publicar, transmitir, distribuir, vender, conceder licencias, alquilar, exhibir públicamente ni realizar publicidad, editar, adaptar ni crear un trabajo derivado de, ni de cualquier manera, de cualquier Contenido o cualquier otra parte del Sitio web sin nuestro consentimiento por escrito para hacerlo
                    <br />
                    <br />
                    Tampoco se le permite utilizar el Sitio web ni acceder al Sitio web ni a cualquiera de los contenidos con fines comerciales.
                    <br />
                    <br />
                    A pesar de esto, puede ver, usar, descargar e imprimir partes seleccionadas del Sitio web únicamente para su uso personal, no comercial, informativo y siempre que no vuelva a publicar el Contenido y que lo mantenga intacto y no oculte, altere ni destruya ningún derecho de autor, marca comercial, marca de servicio, atribuciones, patentes y otros avisos de propiedad.

                </p>
                <h3>3. Conducta del usuario</h3>
                <p>Tenga en cuenta que usted es responsable de realizar todas las operaciones necesarias para tener acceso a nuestro Sitio web, lo que incluye asegurarse de que cuenta con el equipo y la conectividad a Internet suficientes como para que su dispositivo pueda acceder al Sitio web. También es responsable de garantizar que todas las personas que accedan a nuestro Sitio web a través de su conexión a Internet conozcan estos Términos de uso y cualquier otro término y condición aplicable que se le notifique, y que cumplan con ellos. Al utilizar el Sitio web, usted se compromete a:
                    <br />
                    <br />
                    • no usar el Sitio web ni acceder a él con fines fraudulentos o ilegales;
                    <br />
                    <br />
                    • no utilizar el Sitio web ni acceder a él para suplantar a ninguna persona ni afirmar falsamente ni tergiversar su afiliación con una persona o entidad;
                    <br />
                    <br />
                    • no interferir en, ni interrumpir, el funcionamiento del Sitio web ni su acceso;
                    <br />
                    <br />
                    • no transmitir ni poner a disposición el Sitio web ni facilitar el acceso a él de cualquier virus, gusano, troyano, bomba de tiempo, spyware u otro código informático, archivo o programa que sea dañino o invasivo o pueda o esté destinado a dañar o piratear el funcionamiento de, o supervisar el uso de, cualquier hardware, software o equipo;
                    <br />
                    <br />
                    • no restringir ni inhibir la capacidad de otra persona de acceder o usar el Sitio web;
                    <br />
                    <br />
                    • no modificar, adaptar ni traducir cualquier parte del Sitio web;
                    <br />
                    <br />
                    • no eliminar, ocultar ni modificar ningún aviso sobre derechos de autor, marcas comerciales u otros derechos de propiedad del Sitio web; o
                    <br />
                    <br />
                    • no usar ningún robot, rastreador, aplicación de búsqueda/recuperación u otro dispositivo manual o automático para recuperar, indexar, extraer, realizar minería de datos, recopilar o reproducir de cualquier modo el Sitio web ni eludir la estructura de navegación o su presentación.
                </p>
                <h3>4. Comentarios</h3>
                <p>
                    Cualquier comentario, información, sugerencia o material enviado a través de este Sitio web o en relación con el mismo (un «Envío») será considerado no confidencial por Balvora a menos que nos informe de lo contrario. Al proporcionar dichos Envíos a Balvora, usted acepta que Balvora tendrá, gratuitamente y sin la necesidad de atribuirle el Envío a usted, el derecho de copiar, mostrar, distribuir y utilizar dicho Envío.
                    <br />
                    <br />
                    También nos otorga el derecho de crear, mostrar y distribuir cualquier derecho de autor u otros derechos de propiedad intelectual en y para, o en base a, los Envíos. Balvora tendrá libertad de usar y/o distribuir tales Envíos sin restricciones y para cualquier propósito. Los Envíos no incluirán ninguna información confidencial del paciente o del médico

                </p>
                <h3>5. Modificación, suspensión y finalización del Sitio web</h3>
                <p>Es importante tener en cuenta que nos reservamos el derecho de modificar, suspender o finalizar la utilización o el acceso al Sitio web, o a cualquier parte del Sitio web, a nuestra discreción. Por ejemplo, podemos rescindir el uso del Sitio web a todos los usuarios si ya no brindamos servicios y productos de Balvora en su región o podemos suspender su acceso al Sitio web debido a la violación por su parte de estos Términos de uso.
                    <br />
                    <br />
                    También podemos actualizar y cambiar el contenido del Sitio web en cualquier momento. Aunque realizamos esfuerzos razonables para actualizar la información del Sitio web, tenga en cuenta que cualquiera de los contenidos de nuestro Sitio web puede estar desactualizado en un momento dado, y no estamos obligados a actualizarlo.
                    <br />
                    <br />
                    También podemos interrumpir el funcionamiento regular del Sitio web, o de cualquier parte del Sitio web, según sea necesario para realizar un mantenimiento rutinario o no rutinario, corregir errores o realizar otros cambios.
                    <br />
                    <br />
                    Tampoco garantizamos que el Sitio web ni cualquier contenido en él, esté libre de errores u omisiones y no ofrecemos garantías, condiciones, compromisos ni otro tipo de garantías (denominadas colectivamente «compromisos»), ya sean de forma expresa o implícita, de que el contenido del Sitio web sea preciso, completo o esté actualizado.

                </p>
                <h3>7. Privacidad y cookies</h3>
                <p>Nuestras políticas respecto a la recopilación, uso, transmisión y tratamiento de sus datos personales proporcionados por usted o recogidos a través del Sitio web (incluida la forma de utilizar las cookies). Debe revisar la Política de privacidad antes de usar el Sitio web y, al igual que con estos Términos de uso, la Política de privacidad puede ser modificada por nosotros en cualquier momento.</p>
                <h3>8. Aviso legal</h3>
                <p>Aunque hacemos todo lo posible para brindarle un Sitio web informativo en el que disfrutará navegar, a menos que hayamos establecido expresamente lo contrario en estos Términos de uso, el Sitio web está disponible para usted «tal cual». Esto significa que no aceptamos ninguna responsabilidad ante usted con respecto a esto (con sujeción al párrafo 9 a continuación). Por lo tanto, es su responsabilidad, y no la nuestra, asegurarse de que el Sitio web sea adecuado para los fines previstos cuando lo utilice
                    <br />
                    <br />
                    Teniendo esto en cuenta, no aceptamos ninguna responsabilidad en cuanto a la idoneidad del Sitio web para satisfacer sus necesidades y excluimos todos los compromisos expresos o implícitos, que incluyen, entre otros:
                    <br />
                    <br />
                    • que el acceso al Sitio web sea ininterrumpido o libre de errores;
                    <br />
                    <br />
                    • que el Sitio web o el servidor informático desde el cual está disponible el Sitio web, están libres de virus u otros componentes dañinos; y
                    <br />
                    <br />
                    • la exactitud, el contenido, la puntualidad, la exhaustividad, la confiabilidad, la calidad o la idoneidad de cualquier contenido que se encuentre o que se envíe a través del Sitio web o que haya disponible de cualquier otro modo en relación con el Sitio web.

                </p>
                <h3>9. Limitación de responsabilidad</h3>
                <p>No seremos responsables ante usted de ninguna pérdida o daño causados por un virus, ataque de denegación de servicio distribuido o cualquier otro material nocivo tecnológicamente que pueda infectar sus equipos informáticos, programas informáticos, datos u otro material propietario debido al uso de nuestro Sitio web, o de cualquier otro sitio web vinculado a él.
                    <br />
                    También reconoce y acepta que el uso del Sitio web depende del funcionamiento adecuado y efectivo de Internet y de los equipamientos y servicios de terceras partes (incluido su propio dispositivo o navegador web), y de que no garantizamos y no seremos responsables de ello de ninguna forma.
                    <br />
                    <br />
                    Si usted es consumidor y está utilizando este Sitio web, tenga en cuenta que solo proporcionamos nuestro Sitio web para uso privado y doméstico. No puede utilizar nuestro Sitio web con fines comerciales o para negocios y no tendremos responsabilidad alguna frente a usted por daños o pérdidas indirectos, especiales, punitivos, consiguientes o por pérdidas de datos, lucro cesante, ingresos, negocios o prestigio profesional.

                </p>
                <h3>10. Nuestros derechos</h3>
                <p>Podemos transmitir todos nuestros derechos y obligaciones establecidos en estos Términos de uso a otra organización, pero ello no afectará a sus derechos ni a nuestras obligaciones estipuladas en estos Términos de uso.
                    <br />
                    <br />
                    Si no insistimos en que usted cumpla con cualquiera de sus obligaciones establecidas en estos Términos de uso, o si no ejercemos ninguno de nuestros derechos contra usted o si nos demoramos en hacerlo, ello no significará que hayamos renunciado a esos derechos y no significará tampoco que usted no tenga que cumplir con esas obligaciones. Si decidimos renunciar a nuestro derecho de ejecutar un incumplimiento o una violación de estos Términos de uso por su parte, lo haremos únicamente por escrito.

                </p>
                <h3>11. Servicio de Nutrición</h3>
                <p>Balvora se reserva el derecho a rechazar cualquier servicio de Nutrición sin motivo alguno.
                </p>
                <h3>12. Términos de pago</h3>
                <p>Los compradores pagan a Balvora para crear un pedido desde la página de servicio, usando el botón Pagar. Balvora se asocia con proveedores de servicios de pago con el fin de cobrar todos los pagos de los compradores.
                    <br />
                    <br />
                    No puede pagar utilizando algún método que no sea a través del sitio Balvora.com.
                    <br />
                    <br />
                    En caso de que le hayan pedido que utilice un método de pago alternativo, infórmelo inmediatamente a Balvora.
                    <br />
                    <br />
                    Al utilizar el método de pago propuesto por Balvora usted declara y garantiza que: (a) está legalmente autorizado a proporcionar dicha información; (b) está legalmente autorizado o tiene permiso para realizar pagos utilizando los métodos de pago; (c) si es empleado o agente de una empresa o persona propietaria del método de pago, esa empresa o persona lo autoriza a utilizar el método de pago para realizar pagos en; y (d) dichas acciones no violan ninguna ley aplicable
                    <br />
                    <br />
                    Balvora se reserva el derecho de cancelar o rechazar cualquier transacción en caso de detectar actividades fraudulentas o no autorizadas
                    <br />
                    <br />
                    1. Cobros:
                    <br />
                    <br />
                    • Balvora acepta pagos en euros y dólares estadounidenses
                    <br />
                    <br />
                    • El comprador podrá seleccionar la moneda deseada durante el proceso de pago
                    <br />
                    <br />
                    • Los cobros se realizarán a través de la pasarela de pago Stripe
                    <br />
                    <br />
                    • El monto total a pagar será indicado claramente durante el proceso de compra.
                    <br />
                    <br />
                    • El cobro del plan nutricional se realizará antes de enviar la informacion y antes de que Balvora proceda a crear el plan nutricional solicitado por el comprador.
                    <br />
                    <br />
                    • Los precios están sujetos a cambios sin previo aviso.
                    <br />
                    <br />
                    • Los compradores son responsables de pagar todos los impuestos directos o indirectos, incluido el GST, el IVA o el impuesto sobre la renta, que puedan aplicarse según su residencia, ubicación o de otro tipo, según las disposiciones de su jurisdicción. Los compradores declaran y garantizan que cumplen y cumplirán en todo momento con sus obligaciones según las disposiciones del impuesto sobre la renta en su jurisdicción. El precio que se muestra en la página de Balvora incluye todos los impuestos y cargos que puedan aplicarse a los compradores.
                    <br />
                    <br />
                    2. Devoluciones:
                    <br />
                    <br />
                    • Si Balvora decide no crear el plan nutricional por cualquier motivo, se devolverá íntegramente el importe pagado por el comprador a excepción de cualquier tasa o comisión que se le aplique el procesador de pago Stripe o el banco para la transacción.
                    <br />
                    <br />
                    • Para solicitar una devolución, el cliente deberá ponerse en contacto con nuestro equipo de soporte y proporcionar la información necesaria para verificar la transacción.
                    <br />
                    <br />
                    • Las solicitudes de devolución deberán incluir información detallada sobre el producto, número de pedido y motivo de la devolución.
                    <br />
                    <br />
                    • Una vez que se haya realizado el plan nutricional por parte de Balvora, no se podrá realizar ningún reembolso, ya que el trabajo ha sido realizado.
                    <br />
                    <br />
                    • El reembolso se realizará utilizando el mismo método de pago utilizado en la compra original.
                    <br />
                    <br />
                    • El tiempo necesario para que el reembolso se refleje en la cuenta del cliente puede variar dependiendo del método de pago y las políticas del banco o institución financiera correspondiente.
                    <br />
                    <br />
                    Al utilizar nuestros servicios de cobro online, usted acepta cumplir con estos términos y condiciones. Balvora se reserva el derecho de modificar o actualizar estos términos en cualquier momento sin previo aviso. Es responsabilidad del cliente revisar periódicamente estos términos para estar al tanto de cualquier cambio. Si tiene alguna pregunta o inquietud, por favor contáctenos a través de nuestros canales de soporte.
                    <h3>13. Disputas legales</h3>
                    <p>Cualquier disputa relacionada con estos Términos se regirá e interpretará de conformidad con las leyes de USA. Cada uno de los párrafos de estos Términos de uso opera de forma separada. Esto significa que, si algún tribunal o autoridad local decide que cualquiera de ellos es ilegal o no ejecutable, los párrafos restantes seguirán teniendo total vigencia y efecto.</p>
                    <h3>14. Contacto</h3>
                </p>
                <p>
                    Si cree que alguno de sus derechos de autor (u otros derechos de propiedad intelectual) se han visto infringidos en el Sitio web o que hay algún contenido que cree que es ilegal o infringe sus derechos o los de una tercera parte, póngase en contacto con nosotros en la dirección privacy@Balvora.com.
                    <br />
                    <br />
                    Si tiene alguna otra pregunta o reclamación respecto a estos Términos de uso o el Sitio web, póngase en contacto con nosotros a través del correo electrónico privacy@Balvora.com o escríbanos a Nebula Nexus LLC, 8206 Louisiana Blvd NE Ste B, #10080, Albuquerque, NM 87113.
                    <br />
                    <br />
                    Copyright © Nebula Nexus LLC Todos los derechos reservados. Balvora y el logotipo de Balvora son marcas o marcas registradas de Nebula Nexus LLC.
                </p>
            </main>
        </div>
    );
};

export default TermsAndConditions;
