import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, useLocation } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { Plans } from "./pages/Plans/Plans";
import { Footer } from "./components/Footer/Footer";
import { LatestNews } from "./pages/LatestNews/LatestNews";
import CookiesPolicy from "./pages/CookiesPolicy/CookiesPolicy";
import CookiesModal from "./components/CookiesModal/CookiesModal";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Privacy from "./pages/Privacy/Privacy";
import PaymentPage from "./pages/Payment/Payment";
import { useEffect } from 'react';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)

    return () => {

    }
  }, [location.pathname]) 


  return (
    <div className="App">
      <CookiesModal />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/latest-news" element={<LatestNews />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/payment" element={<PaymentPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
