import React, { useEffect, useState } from 'react'
import { ErrorAlert } from '../Messages/Messages';
import './ButtonWithBorder.css'


export const ButtonWithBorder = ({ text, type, onClick }) => {
  const [value, setValue] = useState("");
  const [shownValue, setShownValue] = useState(text);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleAdd = (text, val) => {
    if (type === "email") {
      if (isValidEmail(val)) {
        onClick(text, val);
        setShownValue(value);
      } else {
        ErrorAlert("Por favor introduzca un correo electrónico válido");
      }
    } else {
      onClick(text, val);
      setShownValue(value);
    }
  }

  useEffect(() => {
    setShownValue(text);

    return () => {

    }
  }, [text]);


  return (
    <div className="dropdown ButtonWithBorderDropdown">
      <button className="btn ButtonWithBorder ColoredRoundedBorder dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        <span dangerouslySetInnerHTML={{ __html: shownValue }}></span>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <textarea placeholder='Escribe aquí' onChange={(e) => setValue(e.target.value)} />
        </li>
        <hr />
        <div className='buttons'>
          <button className='btn'>Cancelar</button>
          <button className='btn' onClick={() => handleAdd(text, value)}>Agregar</button>
        </div>
      </ul>
    </div>
  )
}
