import { toast } from 'react-toastify';

export const SuccessAlert = (messages) => {
    toast.success(messages)
}

export const ErrorAlert = (messages) => {
    toast.error(messages)

}
export const WarningAlert = (messages) => {
    toast.warning(messages)
}
