import React from 'react'
import './AnimalsComp.css'
// import animalpic from '../../../public/assets/animals.svg'

export const AnimalsComp = () => {
  return (
    <div className='AnimalsComp'>
      <img src="/assets/animal.png" alt='' />
    </div>
  )
}
