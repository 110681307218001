import React from "react";
import { Header } from "../../components/Header/Header";
import "./Privacy.css";

const Privacy = () => {
    return (
        <div className="Privacy">
            <Header text="Privacidad" />
            <main>
                <h3>Privacidad</h3>
                <p>Actualizado a fecha 08/03/2024</p>
                <h3>Introducción</h3>
                <p>Su privacidad es muy importante para Nebula Nexus LLC. Esta Declaración de privacidad describe cómo recopilamos, protegemos, usamos y compartimos su Información personal (es decir, información sobre una persona física identificada o identificable).</p>

                <p>Al utilizar nuestros Sitios, usted da su consentimiento a nuestra recopilación, uso, divulgación, transferencia y procesamiento de Información personal de acuerdo con esta Declaración de privacidad.</p>

                <p>Información que recopilamos de usted</p>

                <p> Recopilamos Información personal de clientes. Dicha información puede incluir su nombre, dirección, número de teléfono, dirección de correo electrónico, dirección IP u otros datos identificadores habituales, como ubicación, fecha de nacimiento o edad, género, información sobre sus ingresos, tarjeta de crédito y otra información financiera relacionada con el pago por servicios, fotografías y otra información que decida proporcionar.</p>

                <p>Al recoger datos sobre salud en nuestros Sitios, le solicitamos consentimiento explícito para recopilar los datos confidenciales y la aceptación de los términos de esta Declaración de privacidad.</p>

                <p>También recopilaremos información sobre usted y su uso de nuestros Sitios como se describe en la siguiente sección Cookies y anuncios.</p>

                <p>En ocasiones puede optar por proporcionarnos Información personal sobre otras personas para que, por ejemplo, podamos ponernos en contacto con ellos con información sobre nosotros. Usted solo debe compartir la Información personal de ellos con nosotros si ha obtenido su consentimiento y permiso para hacerlo.</p>


                <h3>¿Qué hacemos con la información que recopilamos?</h3>
                <p>• Utilizamos su Información personal para una variedad de propósitos comerciales, entre los que se incluyen:</p>
                <p>• Configurar y administrar su cuenta de usuario en nuestros Sitios.</p>
                <p>• Proporcionarle información sobre nuestros productos, servicios, noticias y eventos que creemos que le pueden interesar.</p>
                <p>• Invitarle a participar en nuestras encuestas o convertirse en miembro de un club de fans (si procede).</p>
                <p>• Recopilar información demográfica sobre tendencias de los usuarios, como edad, sexo y niveles de renta generales.</p>
                <p>• Analizar el uso de nuestros servicios desarrollar nuevos servicios y productos y personalizar los productos, servicios u otro tipo de información que ponemos a su disposición.</p>
                <p>• Utilizar contenido cargado a la aplicación para compartir a través de las redes sociales y hacer copias de su contenido para anunciar en Internet, en TV, en publicaciones o en cualquier otro medio. Nunca mostraremos su nombre en ningún material promocional que generemos sin su consentimiento explícito, pero debe tener en cuenta que puede haber personas que reconozcan su fotografía.</p>
                <p>• Utilizamos una sólida combinación de fuentes de datos (online, pedidos, clientes, etc.) para tener una visión completa de nuestros esfuerzos de marketing y de nuestros productos. Estos datos son solo para nosotros y no compartimos su información personal con otras empresas que la utilicen para otros fines que no estén relacionados con su trabajo con nosotros.</p>
                <h3>¿Con quién compartimos su Información personal?</h3>
                <p>Estamos asociados con otras empresas para que nos ayuden en nuestras labores de marketing, comunicaciones y ventas, y es posible que compartamos con ellos su Información personal para estos fines en los casos en los que usted nos haya dado su consentimiento para hacerlo o donde lo permita la ley.</p>
                <p>Además, toda la información que recopilamos, incluida la Información personal, podría revelarse como parte de una compra, reorganización, fusión, adquisición, financiación de deuda, venta de activos de la empresa o transacciones similares reales o potenciales, así como en el caso de insolvencia, bancarrota o suspensión de pagos, en cuyo caso la información personal podría ser transferida a terceras partes como uno de nuestros activos empresariales.</p>
                <p>También podemos divulgar Información personal: si creemos que dicha divulgación es requerida por la ley, una orden judicial o un proceso judicial; bajo el proceso de descubrimiento de pruebas en un pleito; para hacer cumplir nuestros derechos jurídicos, políticas o contratos; para cobrar importes adeudados a Nebula Nexus LLC cuando creamos que la divulgación es necesaria o apropiada para prevenir daños físicos o pérdidas financieras o en relación con una investigación o enjuiciamiento de una actividad ilegal sospechosa o real; o si de buena fe creemos que la revelación es necesaria o aconsejable de alguna manera.</p>
                <h3>Cookies y anuncios</h3>
                <p>Al igual que muchos sitios web, nuestros Sitios usan cookies. Además, utilizamos publicidad basada en intereses para ofrecerle anuncios que se adapten a los suyos. Consulte la sección Cookies y anuncios para obtener más información.</p>
                <h3>Seguimiento online</h3>
                <p>La opción No realizar seguimiento (DNT, por sus siglas en inglés) es una preferencia de privacidad que los usuarios pueden establecer en determinados navegadores web. DNT es una manera que tienen los usuarios de comunicar a los sitios web y servicios que no desean que se recopile cierta información sobre sus visitas a páginas web a lo largo del tiempo y en todos los sitios web o servicios en línea. Nebula Nexus LLC no reconoce ni responde a las señales DNT iniciadas por el navegador.</p>
                <h3>Enlaces a otros sitios</h3>
                <p>Nuestros Sitios pueden contener enlaces a otros sitios simplemente por razones de comodidad. Nebula Nexus LLC no se hace responsable de las prácticas de privacidad ni del contenido de otros sitios web. La inclusión de los enlaces no significa nuestra aprobación de la información o de la empresa. Esto significa que, si usted se conecta a otros sitios a través del nuestro, no somos responsables y queda fuera de nuestro control lo que estos hagan con su información. Además, tiene la oportunidad de compartir su información con otros sitios web, por ejemplo, en las redes sociales. Podemos proporcionarle un conveniente enlace para que pueda hacer esto fácilmente, pero no somos responsables y no podemos controlar dichos sitios web ni los mensajes ni la información que usted comparta. Le recomendamos que revise las políticas de privacidad y las prácticas de recopilación de información de los otros sitios web y de los sitios de redes sociales.</p>
                <h3>Marketing y no contactar</h3>
                <p>En caso de que nos haya dado su consentimiento (cuando sea necesario), es posible que en ocasiones le enviemos información sobre nosotros.</p>
                <p>Nebula Nexus LLC le ofrece la posibilidad de solicitar darse de baja para dejar de recibir comunicaciones electrónicas procedentes de Nebula Nexus LLC o de nuestros socios retirando su consentimiento. Si no quiere recibir nuestras comunicaciones, le rogamos que nos informe de ello al recopilar su información o nos escriba a remove@balvora.com y se asegure de mencionar en qué país se encuentra. También puede utilizar el método para cancelar su suscripción incluido en nuestros correos electrónicos. Concédanos tiempo suficiente para poder procesar su petición.</p>
                <p>Debe tener en cuenta que dicha retirada de consentimiento no se aplica a las comunicaciones que son necesarias, como la respuesta a una pregunta formulada por usted, y que dejará de tener efecto si usted más adelante nos solicita algún tipo de información, por ejemplo, si se registra para recibir alertas de noticias.</p>
                <h3>Transferencias internacionales de datos</h3>
                <p>Nebula Nexus LLC opera a nivel mundial y por lo tanto es posible que realicemos transferencias de su Información personal a otros países donde Nebula Nexus LLC opere, incluidos países distintos de su país de residencia. Su Información personal también pueden ser almacenada en nuestros servidores situados fuera de su país de residencia. Sin embargo, seguiremos protegiendo su información de acuerdo con esta Declaración de privacidad y con nuestras Normas corporativas vinculantes (BCR, por sus siglas en inglés). Al proporcionarnos su Información personal, usted acepta tales transferencias, procesamiento y/o almacenamiento en cualquier parte del mundo, incluidos los Estados Unidos.</p>
                <h3>Seguridad</h3>
                <p>La seguridad de toda la Información personal proporcionada a Nebula Nexus LLC es importante para nosotros, y Nebula Nexus LLC se compromete a tomar medidas razonables diseñadas para proteger su Información personal. Nuestros Sitios incorporan medidas de seguridad para intentar protegerle de la destrucción, la pérdida, el uso incorrecto y la alteración, la divulgación o el acceso no autorizado de los datos que estén bajo nuestro control. Nuestros portales web, transmiten la información (incluida la Información personal) sobre el paciente de forma segura a través del protocolo HTTPS. Estos portales requieren cuentas de clientes y no están a disposición del público general. Desafortunadamente, no hay transmisión de datos a través de Internet ni almacenamiento de información del que pueda garantizarse su seguridad al 100 %. Por ello, aunque Nebula Nexus LLC se esfuerza por proteger su Información personal, no podemos asegurar ni garantizar la seguridad de toda la información que usted transmita a Nebula Nexus LLC haciéndolo bajo su propia responsabilidad.</p>
                <h3>Actualizaciones</h3>
                <p>Es posible que esta declaración sufra actualizaciones periódicamente según lo consideremos necesario a nuestro exclusivo criterio. Cuando lo hagamos, indicaremos la última fecha de actualización al principio de la declaración. Se proporcionará un aviso de dicha actualización y/o modificación en nuestros Sitios o directamente a usted, si fuese requerido por la ley. Le animamos a que revise esta declaración periódicamente para poder mantenerse informado sobre cómo obtenemos, utilizamos y compartimos la Información personal. Su relación continuada con Nebula Nexus LLC después de la publicación o la notificación de cualquier Declaración de privacidad modificada constituirá su acuerdo de estar sujeto a dichos cambios. Cualquier cambio a esta Declaración de privacidad entrará en vigor inmediatamente después de ser publicado o proporcionado de otra manera por Nebula Nexus LLC.</p>
                <h3>CONTACTO</h3>
                <p>Si tiene alguna pregunta sobre esta Declaración de privacidad, las prácticas de privacidad de nuestros Sitios, su información o sus derechos sobre su información, o sobre su actividad con nuestros Sitios, le rogamos que se ponga en contacto con:</p>
                <p>Sede mundial</p>
                <p>Nebula Nexus LLC</p>
                <p>Privacy Office</p>
                <p>8206 Louisiana Blvd NE Ste B, #10080, Albuquerque, NM 87113</p>
                <p>Estados Unidos</p>
                <p>privacy@ balvora.com</p>
            </main>
        </div>
    );
};

export default Privacy;
