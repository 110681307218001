import React from 'react'
import './ButtonComp.css'

export const ButtonComp = ({ text, plans, onClick }) => {

  return (
    <button className="btn ButtonComp" onClick={onClick}>
      {
        plans ?
          <img src="/assets/rightarrowwithbg.svg" alt='' />
          :
          <img src="/assets/rightarrow.svg" alt='' />
      }
      <span>
        {text}
      </span>
    </button>
  )
}
