import React, { useState } from "react";
import "./Plans.css";
import { Header } from "../../components/Header/Header";
import { ButtonWithBorder } from "../../components/ButtonWithBorder/ButtonWithBorder";
import { ButtonComp } from "../../components/ButtonComp/ButtonComp";
import { useNavigate } from "react-router-dom/dist";
import { ToastContainer } from 'react-toastify';
import { ErrorAlert } from "../../components/Messages/Messages";
import { Helmet } from "react-helmet";

export const Plans = () => {
  const router = useNavigate();
  const [data, setData] = useState({
    "Tu Nombre": "Tu Nombre",
    "Tu correo electrónico": "Tu correo electrónico",
    "Repite tu correo electrónico": "Repite tu correo electrónico",
    "Tipo de animal": "Tipo de animal",
    "Raza": "Raza",
    "Genero": "Genero",
    "Edad": "Edad",
    "Peso actual": "Peso actual",
    "Condición de salud actual": "Condición de salud actual",
    "Estilo de vida": "Estilo de vida",
    "Tipo de Alimentación actual": "Tipo de Alimentación actual",
    "¿Estas suministrándole algún medicamento? En caso afirmativo, especifica cuál": "¿Estas suministrándole algún medicamento? <br /> En caso afirmativo, especifica cuál",
    "Dinos todo lo que necesitamos saber sobre tu mascota": "Dinos todo lo que necesitamos saber sobre tu mascota",
  });

  const handleInputChange = (title, value) => {
    setData((prevData) => ({
      ...prevData,
      [title]: value,
    }));
  };

  const handleSubmit = () => {
    if (data["Tu correo electrónico"] !== data["Repite tu correo electrónico"]) {
      ErrorAlert("El correo electrónico repetido y el correo electrónico no coinciden")
    }
    else if (
      data["Tu Nombre"] !== "" &&
      data["Tu correo electrónic"] !== "" &&
      data["Repite tu correo electrónico"] !== "" &&
      data["Tipo de animal"] !== "" &&
      data["Raza"] !== "" &&
      data["Genero"] !== "" &&
      data["Edad"] !== "" &&
      data["Peso actual"] !== ""
    ) {
      localStorage.setItem("userData", JSON.stringify(data));
      router("/payment")
    }
    else {
      ErrorAlert("Por favor complete todos los detalles anteriores");
    }
  };

  console.log(data);

  return (
    <div className="Plans">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Balvora - Plan Nutricional Ideal para Tu Mascota</title>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.balvora.com/plans" />
        <meta name="description" content="At Balvora we understand that each pet is unique, as are their nutritional needs. We offer personalized natural nutritional plans for dogs, cats, parrots, fish, and other exotic animals, promoting their health and well-being. Get a custom plan for only €49." />
        <meta name="keywords" content="Plan Nutricional Ideal para Tu Mascota, personalized pet diets, natural pet nutrition, pet health, custom pet meal plans, pet dietary needs, dog diet, cat diet, exotic pet nutrition, pet well-being, cat purring, cat behavior, feline health, cat communication, therapeutic purring, dogs sense of smell, canine olfaction, dog detection abilities, dog health monitoring, parrot speech, parrot language, bird intelligence, parrot communication, sea turtles, marine life, aquatic animals, marine conservation, ocean biodiversity, aquarium fish, fish care, aquarium setup, fish feeding, aquatic pets, rabbit care, pet rabbits, rabbit diet, rabbit housing, rabbit health, hamsters in the wild, rodent behavior, hamster diet, hamster habitat, iguana care, pet iguanas, reptile care, iguana diet, iguana habitat, python care, pet pythons, snake care, python diet, python habitat" />
        <meta property="og:title" content="Balvora - Plan Nutricional Ideal para Tu Mascota" />
        <meta property="og:description" content="Discover personalized natural nutritional plans for your pets at Balvora. Whether it's a dog, cat, parrot, fish, or any exotic animal, our plans cater to their unique dietary needs. Start your pet's journey to better health for only €49." />
        <meta property="og:url" content="https://www.balvora.com/plans" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.balvora.com/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Balvora - Plan Nutricional Ideal para Tu Mascota" />
        <meta name="twitter:description" content="Personalized natural nutritional plans for your pets, promoting their health and well-being. Get a custom plan for only €49 from Balvora." />
        <meta name="twitter:image" content="https://www.balvora.com/logo.png" />
      </Helmet>
      <ToastContainer />
      <Header text="Plan Nutricional Ideal para Tu Mascota" />
      <main>
        <h5>
          Para comenzar a crear una dieta personalizada para su mascota,
          necesitaremos algunos detalles específicos. Este proceso es rápido y
          sencillo. Simplemente completa el siguiente formulario con
          información como el tipo de animal, edad, peso actual, condiciones
          de salud existentes, y cualquier otra información que creas que
          debamos saber. Cada dato nos ayudará a diseñar un plan nutricional
          que se ajuste perfectamente a las necesidades únicas de su mascota.
          ¡Gracias por confiar en nosotros para cuidar de su compañero!
        </h5>
        <section className="selectSection">
          <h3>Sobre ti</h3>
          <div className="btnContainer">
            <ButtonWithBorder text={data["Tu Nombre"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder type="email" text={data["Tu correo electrónico"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder type="email" text={data["Repite tu correo electrónico"]} onClick={(title, value) => handleInputChange(title, value)} />
          </div>
          <h3>Sobre tu mascota</h3>
          <div className="btnContainer last">
            <ButtonWithBorder text={data["Tipo de animal"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["Raza"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["Genero"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["Edad"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["Peso actual"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["Condición de salud actual"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["Estilo de vida"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["Tipo de Alimentación actual"]} onClick={(title, value) => handleInputChange(title, value)} />
            <ButtonWithBorder text={data["¿Estas suministrándole algún medicamento? En caso afirmativo, especifica cuál"]} onClick={(title, value) => handleInputChange("¿Estas suministrándole algún medicamento? En caso afirmativo, especifica cuál", value)} />
            <ButtonWithBorder text={data["Dinos todo lo que necesitamos saber sobre tu mascota"]} onClick={(title, value) => handleInputChange(title, value)} />
          </div>
        </section>
      </main>
      <section className="lastSection">
        <h2>Ya casi está listo. Solo confirma tu compra y recibirás el
          plan personalizado de tu mascota directamente en tu
          correo.</h2>
        <ButtonComp plans={true} text="Ir a la pasarela de pago" onClick={handleSubmit} />
      </section>
    </div>
  );
};
