import React from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";

export const Footer = () => {
  const router = useNavigate();

  return (
    <footer className="footer">
      <div className="mainLogo">
        <Link to="/">
          <img src={"/assets/logo.svg"} alt='Logo' />
        </Link>
      </div>
      <div>
        <button className="btn" onClick={() => router("/latest-news")}>
          Últimas Noticias del Mundo Animal
        </button>
      </div>
      <div className="bottom">
        <div className="email">
          <a href="mailto: info@balvora.com">info@balvora.com</a>
        </div>
        <div className="socialIcons">
          <a href="https://www.tiktok.com/" target="_blank" rel="noreferrer" className="tiktok">
            <img src={"/assets/tiktok.svg"} alt="" />
          </a>
          <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" className="camera">
            <img src={"/assets/instagram.svg"} alt="" />
          </a>
        </div>
        <div className="footerLinks">
          <Link to="/terms">Términos y Condiciones</Link>
          <Link to="/privacy">Aviso de Privacidad</Link>
          <Link to="/cookies-policy">Uso de cookies</Link>
        </div>
        <div className="lastSection">
          <div className="leftCorner">
            <p>Pago Seguro</p>
            <img src={"/assets/visa.svg"} alt="" />
          </div>
          <div className="lastText">
            © Balvora. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};
