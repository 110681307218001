import React from 'react'
import './Header.css'
import { ButtonComp } from '../ButtonComp/ButtonComp'
import { Link, useNavigate } from "react-router-dom";

export const Header = ({ text, showBtn }) => {
  const router = useNavigate();

  return (
    <header>
      <div>
        <div className='mainLogo'>
          <Link to="/">
            <img src={"/assets/logo.svg"} alt='Logo' />
          </Link>
        </div>
        <div className='headerPara'>
          <h1>
            {text}
          </h1>
        </div>
        {
          showBtn &&
          <div>
            <ButtonComp text="Diseñemos Juntos el Plan Nutricional Ideal para Tu Mascota !" onClick={() => router("/plans")} />
          </div>
        }
      </div>
    </header>
  )
}
