import React, { useState } from 'react';
import Modal from 'react-modal';
import "./CookiesModal.css"

const CookiesModal = () => {
    const [isOpen, setIsOpen] = useState(!localStorage.getItem('cookiesAccepted'));

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        handleCloseModal();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Cookies Policy"
            className="CookiesModal"
        >
            <img onClick={handleCloseModal} src="/assets/cross.svg" alt='cross' />
            <h2>Configuración de Cookies</h2>
            <p>Utilizamos cookies y tecnologías similares para personalizar contenido, adaptar y medir anuncios, y brindar una mejor experiencia. Al hacer clic en aceptar, aceptas esto, tal como se describe en nuestra Política de Cookies.</p>
            <div className='buttons'>
                <button className='btn' onClick={handleAcceptCookies}>Aceptar</button>
                <button className='btn ColoredRoundedBorder' onClick={handleCloseModal}><span>Rechazar</span></button>
            </div>
        </Modal>
    );
};

export default CookiesModal;