import React from "react";
import { Header } from "../../components/Header/Header";
import "./CookiesPolicy.css";

const CookiesPolicy = () => {
    return (
        <div className="CookiesPolicy">
            <Header text="Política de Cookies" /> 
            <main>
                <h3>Política de Cookies</h3>
                <p>Última actualización: 08/01/2024</p>
                <p>
                    Esta Política de Cookies explica qué son las cookies y cómo las
                    utilizamos en nuestro sitio web. Debes leer esta política para
                    comprender qué tipos de cookies utilizamos, la información que
                    recopilamos mediante el uso de cookies y cómo se utiliza esa
                    información.
                </p>
                <h3>¿QUÉ SON LAS COOKIES?</h3>
                <p>
                    Las cookies son pequeños archivos de texto que se almacenan en tu
                    dispositivo cuando visitas un sitio web. Son ampliamente utilizadas
                    para que los sitios web funcionen correctamente y para proporcionar
                    una mejor experiencia de usuario, así como para recopilar información
                    sobre tus hábitos de navegación y preferencias.
                </p>
                <h3>¿Cómo utilizamos las cookies?</h3>
                <p>
                    Utilizamos cookies por varias razones, que se detallan a continuación:
                </p>
                <h2>• Cookies esenciales:</h2>
                <p>
                    Estas cookies son necesarias para el funcionamiento básico de nuestro
                    sitio web. Incluyen, por ejemplo, cookies que te permiten iniciar
                    sesión en áreas seguras de nuestro sitio.
                </p>
                <h2>• Cookies de rendimiento:</h2>
                <p>
                    Estas cookies nos permiten reconocerte y contar las visitas en nuestro
                    sitio web, así como ver cómo los visitantes navegan por el sitio. Esto
                    nos ayuda a mejorar el funcionamiento de nuestro sitio, por ejemplo,
                    asegurándonos de que los usuarios encuentren fácilmente lo que están
                    buscando.
                </p>
                <h2>• Cookies de funcionalidad:</h2>
                <p>
                    Estas cookies se utilizan para reconocerte cuando regresas a nuestro
                    sitio web y nos permiten personalizar nuestro contenido para ti,
                    saludándote por tu nombre y recordando tus preferencias, como tu
                    idioma o región.
                </p>
                <h2>• Cookies de publicidad:</h2>
                <p>
                    Estas cookies registran tu visita a nuestro sitio web, las páginas que
                    has visitado y los enlaces que has seguido. Utilizaremos esta
                    información para hacer que nuestro sitio web y la publicidad que se
                    muestra en él sean más relevantes para tus intereses. También podemos
                    compartir esta información con terceros con este fin.
                </p>
                <h3>¿Cómo puedo controlar las cookies?</h3>
                <p>
                    Puedes controlar y/o eliminar las cookies según desees. Puedes
                    eliminar todas las cookies que ya están en tu ordenador y puedes
                    configurar la mayoría de los navegadores para evitar que se coloquen.
                    Sin embargo, si lo haces, es posible que tengas que ajustar
                    manualmente algunas preferencias cada vez que visites un sitio y que
                    algunas funciones y servicios no funcionen.
                </p>
                <h3>Cambios en nuestra política de cookies</h3>
                <p>
                    Cualquier cambio que realicemos en nuestra política de cookies se
                    publicará en esta página y, cuando corresponda, se te notificará por
                    correo electrónico. Asegúrate de revisar periódicamente esta política
                    para estar al tanto de cualquier cambio o actualización.
                </p>
            </main>
        </div>
    );
};

export default CookiesPolicy;
