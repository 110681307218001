import React from "react";
import "./LatestNews.css";
import HomeSection from "../../components/HomeSection/HomeSection";
import { Header } from "../../components/Header/Header";
import { Helmet } from "react-helmet";

export const LatestNews = () => {
  const sectionsArray = [
    {
      bg: "#984C8A",
      image: "/assets/cat.png",
      imagePosition: "right",
      title: "Los Misterios del Ronroneo: La Ciencia Detrás del Gato que Ruge",
      description: <p>El ronroneo de los gatos es un fenómeno fascinante que ha desconcertado a científicos y amantes de los felinos durante años. Aunque se asocia comúnmente con la felicidad y la comodidad, los gatos también pueden ronronear en situaciones de estrés o dolor. Investigaciones recientes sugieren que el ronroneo puede ser terapéutico tanto para los gatos como para los humanos, ya que las vibraciones de baja frecuencia pueden promover la curación de tejidos y huesos, aliviar el estrés y reducir la presión arterial. Además, se ha descubierto que los gatos utilizan el ronroneo como una forma de comunicación con sus crías y otros gatos, lo que añade otra capa de complejidad a este comportamiento único.</p>
    },
    {
      bg: "#1F295E",
      image: "/assets/dog2.png",
      imagePosition: "right",
      title: "La Asombrosa Capacidad Olfativa de los Perros: Más que una Nariz",
      description: <p>El sentido del olfato de los perros es extraordinario, con una capacidad para detectar olores hasta 100,000 veces más débiles que los humanos. Esta habilidad les permite realizar una variedad de tareas, desde la detección de drogas y explosivos hasta la búsqueda y rescate de personas atrapadas. Los perros también pueden detectar cambios en el estado de salud de los humanos, incluidos niveles bajos de azúcar en la sangre y signos de cáncer. Además, el entrenamiento en olfato puede proporcionar estimulación mental y enriquecimiento para los perros, mejorando su bienestar general.</p>
    },
    {
      bg: "#00CF35",
      image: "/assets/parrot.png",
      imagePosition: "right",
      title: "El Arte del Habla: ¿Los Loros Realmente Entienden lo que Dicen?",
      description: <p>Los loros son famosos por su capacidad para imitar el habla humana, pero su comprensión del lenguaje va más allá de la mera imitación. Estudios han demostrado que algunos loros pueden asociar palabras con objetos y acciones específicas, e incluso formar oraciones simples para expresar deseos o necesidades. Esta habilidad se basa en la capacidad de los loros para aprender a través de la asociación y la repetición, así como en su inteligencia social y cognitiva. Proporcionar a los loros un entorno estimulante y oportunidades para interactuar con humanos puede fomentar su capacidad para comprender y utilizar el lenguaje de manera más sofisticada.</p>
    },
    {
      bg: "#FFAD03",
      image: "/assets/tortoise2.png",
      imagePosition: "right",
      title: "La Fascinante Vida Acuática de las Tortugas Marinas: Navegantes de los Océanos",
      description: <p>
        Las tortugas marinas son criaturas asombrosas que han fascinado a los científicos y amantes del océano durante siglos. Su vida acuática las lleva a través de vastas distancias a lo largo de los océanos del mundo, desde playas tropicales hasta aguas frías del Ártico. Las tortugas marinas desempeñan un papel crucial en los ecosistemas marinos, ayudando a mantener el equilibrio de las poblaciones de medusas y algas, y proporcionando alimento para otras especies, como tiburones y aves marinas. Sin embargo, enfrentan una serie de amenazas, incluyendo la contaminación, la pesca incidental y la pérdida de hábitat, que ponen en peligro su supervivencia. La conservación de las tortugas marinas es esencial para proteger la biodiversidad marina y garantizar la salud de los océanos para las generaciones futuras.      </p>
    },
    {
      bg: "#3F8BF9",
      image: "/assets/fish2.png",
      imagePosition: "right",
      title: "El Fascinante Mundo de los Peces de Acuario: GuÌa para Principiantes",
      description: <p> Los peces de acuario son mascotas populares debido a su belleza y diversidad, pero cuidar de ellos correctamente requiere conocimiento y compromiso. Antes de comenzar un acuario, es importante investigar las necesidades específicas de los peces que planeas mantener, incluyendo sus requisitos de hábitat, alimentación y compatibilidad con otras especies. Además, mantener la calidad del agua es fundamental para la salud de los peces, lo que requiere pruebas regulares y cambios parciales de agua según sea necesario. Proporcionar un ambiente enriquecido con escondites, plantas vivas y decoraciones puede promover el bienestar y el comportamiento natural de los peces en el acuario.</p>
    },
    {
      bg: "#FE6458",
      image: "/assets/rabbit2.png",
      imagePosition: "right",
      title: "Los Conejos como Mascotas: Mitos y Realidades de su Cuidado",
      description: <p> Los conejos son mascotas populares en todo el mundo debido a su naturaleza dócil y cariñosa, pero su cuidado puede requerir más trabajo del que algunos propietarios anticipan. Contrario a la creencia popular, los conejos no son mascotas de bajo mantenimiento y necesitan atención regular en cuanto a su dieta, alojamiento y atención veterinaria. Proporcionar un ambiente adecuado que incluya un espacio espacioso para moverse, una dieta equilibrada que incluya heno, verduras frescas y pellets de alta calidad, y oportunidades para socializar y ejercitarse son fundamentales para el bienestar de los conejos como mascotas.</p>
    },
    {
      bg: "#39C8CC",
      image: "/assets/comun.png",
      imagePosition: "right",
      title: "Cuidados Esenciales para Erizos Africanos Pigmeos como Mascotas: Garantizando su Bienestar",
      description: <p>Los erizos africanos pigmeos son mascotas encantadoras y populares debido a su naturaleza tranquila y su apariencia única. Sin embargo, cuidar adecuadamente de un erizo africano pigmeo requiere atención y compromiso por parte del propietario. Proporcionar un ambiente adecuado es fundamental para el bienestar de un erizo, incluyendo una jaula espaciosa con suficiente espacio para moverse y explorar. Se deben proporcionar sustratos seguros y no tóxicos, así como escondites y juguetes para enriquecer el entorno del erizo. Una dieta equilibrada es esencial para mantener la salud de un erizo, que generalmente consiste en una mezcla de alimentos comerciales para erizos, insectos vivos y frutas frescas. Es importante también manipular suavemente al erizo y proporcionarle tiempo fuera de la jaula para socializar y ejercitarse. Establecer una rutina de cuidado regular y estar atento a cualquier signo de enfermedad o malestar también son partes importantes de la atención de un erizo como mascota.</p>
    },
    {
      bg: "#FEB69C",
      image: "/assets/crocodile2.png",
      imagePosition: "right",
      title: "Las iguanas como Mascotas: Cuidados Esenciales y Consideraciones",
      description: <p>Las iguanas son reptiles fascinantes y populares como mascotas debido a su tamaño impresionante y su apariencia única. Sin embargo, cuidar adecuadamente de una iguana requiere un compromiso significativo, ya que pueden vivir hasta 20 años o más en cautiverio y necesitan un ambiente adecuado para prosperar. Esto incluye proporcionar un terrario espacioso con suficiente espacio para moverse, así como áreas para trepar y esconderse. Además, las iguanas necesitan acceso a luz solar natural o iluminación artificial UVB para mantener su salud ósea y metabólica. Una dieta equilibrada que incluya una variedad de vegetales frescos, frutas y proteínas animales es fundamental para satisfacer sus necesidades nutricionales. Es importante también establecer una relación con un veterinario especializado en reptiles para realizar chequeos de salud regulares y detectar cualquier problema de salud lo antes posible.</p>
    },
    {
      bg: "#7C41CF",
      image: "/assets/rat.png",
      imagePosition: "right",
      title: "Hámsteres en la Naturaleza: La Vida de los Roedores en su Hábitat Natural",
      description: <p>
        En la naturaleza, los hámsteres son roedores activos y adaptables que habitan una variedad de entornos, desde desiertos hasta praderas y bosques. Se alimentan principalmente de semillas, frutas, vegetación y pequeños insectos, utilizando sus bolsas para almacenar comida y sus largas patas traseras para excavar madrigueras subterráneas. Los hámsteres son conocidos por su comportamiento nocturno y su capacidad para almacenar grandes cantidades de comida para su uso durante los meses de invierno. Además, son presas importantes para una variedad de depredadores, incluyendo aves rapaces, zorros y serpientes. Aunque los hámsteres enfrentan una serie de amenazas en la naturaleza, como la pérdida de hábitat y la caza, algunas especies han logrado adaptarse a los entornos modificados por los humanos y prosperar en áreas urbanas y agrícolas. Cuidados Esenciales para Pitones como Mascotas: M         </p>
    },
    {
      bg: "#8ABB9D",
      image: "/assets/snake.png",
      imagePosition: "right",
      title: "Cuidados Esenciales para Pitones como Mascotas: Manteniendo a tu Serpiente Sana y Feliz",
      description: <p>
        Las pitones son serpientes fascinantes que se han vuelto populares como mascotas exóticas debido a su belleza y comportamiento cautivador. Sin embargo, cuidar adecuadamente de una pitón requiere conocimiento y compromiso por parte del propietario. Proporcionar un ambiente adecuado es crucial para el bienestar de una pitón, incluyendo un terrario lo suficientemente grande para permitir el movimiento y la termorregulación adecuada. Se deben proporcionar sustratos adecuados, escondites y ramas para trepar para crear un entorno enriquecedor para la serpiente. Una dieta adecuada es esencial para mantener la salud de una pitón, que generalmente consiste en roedores prensados y ocasionalmente aves. Es importante también establecer una rutina de cuidado regular que incluya chequeos de salud, mantenimiento del terrario y manipulación adecuada de la serpiente para      </p>
    },
  ]
  return (
    <div className="LatestNews">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Balvora - Últimas Noticias en el Mundo Animal</title>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.balvora.com/latest-news" />
        <meta name="description" content="At Balvora we understand that each pet is unique, as are their nutritional needs. We offer personalized natural nutritional plans for dogs, cats, parrots, fish, and other exotic animals, promoting their health and well-being. Get a custom plan for only €49." />
        <meta name="keywords" content="Últimas Noticias en el Mundo Animal, personalized pet diets, natural pet nutrition, pet health, custom pet meal plans, pet dietary needs, dog diet, cat diet, exotic pet nutrition, pet well-being, cat purring, cat behavior, feline health, cat communication, therapeutic purring, dogs sense of smell, canine olfaction, dog detection abilities, dog health monitoring, parrot speech, parrot language, bird intelligence, parrot communication, sea turtles, marine life, aquatic animals, marine conservation, ocean biodiversity, aquarium fish, fish care, aquarium setup, fish feeding, aquatic pets, rabbit care, pet rabbits, rabbit diet, rabbit housing, rabbit health, hamsters in the wild, rodent behavior, hamster diet, hamster habitat, iguana care, pet iguanas, reptile care, iguana diet, iguana habitat, python care, pet pythons, snake care, python diet, python habitat" />
        <meta property="og:title" content="Balvora - Últimas Noticias en el Mundo Animal" />
        <meta property="og:description" content="Discover personalized natural nutritional plans for your pets at Balvora. Whether it's a dog, cat, parrot, fish, or any exotic animal, our plans cater to their unique dietary needs. Start your pet's journey to better health for only €49." />
        <meta property="og:url" content="https://www.balvora.com/latest-news" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.balvora.com/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Balvora - Últimas Noticias en el Mundo Animal" />
        <meta name="twitter:description" content="Personalized natural nutritional plans for your pets, promoting their health and well-being. Get a custom plan for only €49 from Balvora." />
        <meta name="twitter:image" content="https://www.balvora.com/logo.png" />
      </Helmet>
      <Header text="Últimas Noticias en el Mundo Animal" />
      <div className="mt-5">
        {
          sectionsArray?.map((data, index) => {
            return (
              <HomeSection key={index} data={data} />
            )
          })
        }
      </div>
    </div>
  );
};
