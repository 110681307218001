import React from "react";
import "./Home.css";
import HomeSection from "../../components/HomeSection/HomeSection";
import { Header } from "../../components/Header/Header";
import { AnimalsComp } from "../../components/AnimalsComp/AnimalsComp";
import { ButtonComp } from "../../components/ButtonComp/ButtonComp";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Home = () => {
  const router = useNavigate();

  const sectionsArray = [
    {
      bg: "rgba(221, 197, 134, 1)",
      image: "/assets/food.png",
      imagePosition: "right",
      title: "Cuidamos la Nutrición de Tu Mascota",
      description: <p>En Balvora comprendemos que cada mascota es única, al igual que sus necesidades nutricionales. Por eso, ofrecemos planes nutricionales naturales personalizados que fomentan la salud y el bienestar de tu fiel compañero, ya sea perro, gato, loro, pez o cualquier otro animal exótico. <span>Por solo 49€, recibirás directamente en tu correo electrónico un plan nutricional de alimentos naturales y en cantidades diarias exactas, ajustadas a las necesidades específicas de tu mascota.</span> Podemos ayudarte a que tu mascota disfrute de una vida más larga y saludable. ¡Comienza su cambio!</p>
    },
    {
      bg: "rgba(195, 221, 134, 1)",
      image: "/assets/dog2.png",
      imagePosition: "left",
      title: "La Importancia de una Dieta Equilibrada",
      description: <p>Mejora la vida de tu mascota con el poder de una nutrición balanceada. Los nutrientes correctos, naturales y en cantidades adecuadas mejoran la digestibilidad y ayudan a regular los biorritmos esenciales elevando su bienestar a diario. Con nuestros planes nutricionales formulados por veterinarios especializados en nutrición animal garantizamos una dieta optima que hará que tu amigo disfrute de una vida más feliz y activa.</p>
    },
    {
      bg: "rgba(37, 109, 163, 1)",
      image: "/assets/cat.png",
      imagePosition: "right",
      title: "¿ Quién desarrolla los Planes Nutricionales ?",
      description: <p>Nuestros planes nutricionales son formulados por veterinarios especializados en nutrición animal. Cada plan es meticulosamente estudiado y personalizado, utilizando cantidades precisas de alimentos naturales para satisfacer las necesidades específicas de tu mascota. Este enfoque cuidadoso optimiza la salud y el bienestar de tu compañero y le permite disfrutar de una vida más plena.</p>
    },
    {
      bg: "rgba(194, 122, 168, 1)",
      image: "/assets/crocodile.png",
      imagePosition: "left",
      title: "Los Riesgos de una Alimentación Inadecuada",
      description: <p>Una alimentación inadecuada puede comprometer seriamente la salud de tu mascota. Nosotros te
        ofrecemos soluciones específicas, diseñadas para prevenir problemas de salud y asegurar una dieta
        completa y equilibrada. Protege la salud de tu compañero con un plan ajustado a sus necesidades
        específicas. ¡Cuida su bienestar hoy!</p>
    },
    {
      bg: "rgba(149, 199, 226, 1)",
      image: "/assets/pigeon.png",
      imagePosition: "right",
      title: "Economía y Salud Pet-Friendly: Inversión en Nutrición",
      description: <p>Invertir en una dieta equilibrada para tu mascota no solo mantiene su salud óptima, sino que también reduce los costos veterinarios a largo plazo. Las dietas bien balanceadas pueden prevenir enfermedades costosas como la obesidad, diabetes y otras enfermedades. Al optar por alimentos naturales y en cantidades adecuadas, se disminuyen las visitas al veterinario y las necesidades de medicación continua, lo que resulta en ahorros significativos en el cuidado de su mascota.</p>
    },
    {
      bg: "rgba(255, 0, 0, 0.52)",
      image: "/assets/grains.png",
      imagePosition: "left",
      title: "Libera a tu Mascota de los Conservantes y Aditivos Perjudiciales.",
      description: <p>Muchos alimentos comerciales incluyen componentes no ideales que pueden afectar a tu compañero generando serios problemas de salud. Con nuestros planes nutricionales naturales personalizados, ofrecemos dietas seguras y específicamente diseñadas para cada especie y cada necesidad particular. Elige lo mejor para tu mascota: salud y bienestar a medida.</p>
    },
    {
      bg: "rgba(62, 62, 62, 1)",
      image: "/assets/fish.png",
      imagePosition: "right",
      title: "Tu Plan Nutricional a Solo unos Clics",
      description: <p>Adquirir tu plan nutricional en Balvora es fácil y rápido.
        Proporciona los detalles esenciales sobre tu mascota como
        el tipo de animal, raza, edad, género, estilo de vida y
        cualquier condición de salud o particularidad existente.
        Finaliza tu compra y recibe el plan nutricional personalizado
        directamente en tu correo electrónico en menos de 24
        horas.</p>
    },
    {
      bg: "rgba(221, 118, 60, 1)",
      image: "/assets/dog.png",
      imagePosition: "left",
      title: "Nutrición Para Todas las Especies",
      description: <p>
        Balvora ofrece servicios a una diversidad de animales, desde los más comunes como perros y gatos hasta los más exóticos como loros, peces, aves rapaces e incluso insectos. Cada uno de nuestros planes está diseñado para cubrir las necesidades nutricionales específicas de cada especie y adaptándolo a su estilo de vida, asegurando una dieta balanceada y adecuada. Zoos, veterinarios, tiendas de mascotas y dueños de animales, respaldan y avalan nuestra contribución al bienestar animal.
        <br /> <br />
        Tu mascota merece lo mejor y nosotros sabemos cómo hacerlo, solo depende de ti. ¡Dale un plan nutricional que respalde su salud!
      </p>
    },
  ]
  return (
    <div className="Home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Balvora - Dietas personalizadas para tu mascota</title>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.balvora.com" />
        <meta name="description" content="At Balvora we understand that each pet is unique, as are their nutritional needs. We offer personalized natural nutritional plans for dogs, cats, parrots, fish, and other exotic animals, promoting their health and well-being. Get a custom plan for only €49." />
        <meta name="keywords" content="Dietas personalizadas para tu mascota, Planes nutricionales para mascotas, Comida natural para mascotas, Dietas personalizadas para perros,  Dieta Barf, Alimentación saludable para gatos, Nutrición para perros y gatos, Comida casera para mascotas,  Dietas equilibradas para mascotas,  Nutrición personalizada para mascotas, Comida natural para perros,  Comida natural para gatos, Dieta Barf, Plan nutricional para mascotas, Dieta mascotas, personalized pet diets, natural pet nutrition, pet health, custom pet meal plans, pet dietary needs, dog diet, cat diet, exotic pet nutrition, pet well-being, cat purring, cat behavior, feline health, cat communication, therapeutic purring, dogs sense of smell, canine olfaction, dog detection abilities, dog health monitoring, parrot speech, parrot language, bird intelligence, parrot communication, sea turtles, marine life, aquatic animals, marine conservation, ocean biodiversity, aquarium fish, fish care, aquarium setup, fish feeding, aquatic pets, rabbit care, pet rabbits, rabbit diet, rabbit housing, rabbit health, hamsters in the wild, rodent behavior, hamster diet, hamster habitat, iguana care, pet iguanas, reptile care, iguana diet, iguana habitat, python care, pet pythons, snake care, python diet, python habitat" />
        <meta property="og:title" content="Balvora - Dietas personalizadas para tu mascota" />
        <meta property="og:description" content="Discover personalized natural nutritional plans for your pets at Balvora. Whether it's a dog, cat, parrot, fish, or any exotic animal, our plans cater to their unique dietary needs. Start your pet's journey to better health for only €49." />
        <meta property="og:url" content="https://www.balvora.com" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.balvora.com/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Balvora - Dietas personalizadas para tu mascota" />
        <meta name="twitter:description" content="Personalized natural nutritional plans for your pets, promoting their health and well-being. Get a custom plan for only €49 from Balvora." />
        <meta name="twitter:image" content="https://www.balvora.com/logo.png" />
      </Helmet>
      <Header text="Dietas personalizadas para tu mascota" showBtn={true} />
      <AnimalsComp />
      <div>
        {
          sectionsArray?.map((data, index) => {
            return (
              <HomeSection index={index} key={index} data={data} />
            )
          })
        }
      </div>
      <div className="HomeSection ButtonOnlySection text-center">
        <ButtonComp text="Diseñemos Juntos el Plan Nutricional Ideal para Tu Mascota !" onClick={() => router("/plans")} />
      </div>
    </div>
  );
};
