import React, { useState } from 'react';
import Modal from 'react-modal';
import "./DoneModal.css"

const DoneModal = ({ showModal }) => {
    const [isOpen, setIsOpen] = useState(showModal);

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Cookies Policy"
            className="DoneModal"
        >
            <img onClick={handleCloseModal} src="/assets/done.svg" alt='cross' />
            <h2>Pago realizado correctamente</h2>
        </Modal>
    );
};

export default DoneModal;