import React, { useEffect, useState } from "react";
import "./Payment.css";
import moment from "moment";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import { ErrorAlert } from "../../components/Messages/Messages";
import { StripeForm } from "../../components/Stripe";
import { ToastContainer } from 'react-toastify';
import { Header } from "../../components/Header/Header";
import DoneModal from "../../components/DoneModal/DoneModal";
import { useNavigate } from "react-router-dom";


const PaymentPage = () => {
    const router = useNavigate();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const backendURL = "https://balvora1-bef85ddc1f2e.herokuapp.com/api";
    const data = JSON.parse(localStorage.getItem("userData"));
    const [stripeLoading, setStripeLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [showModal, setShowModal] = useState(false);

    const totalPrice = 1;

    const transactionSuccess = async (paymentData) => {
        await axios.post(`${backendURL}/orders/place-order`, {
            placed: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
            totalPrice,
            data,
            paymentData
        }).then(res => {
            localStorage.removeItem("userData");
            if (res.status === 200) {
                setShowModal(true);
                setTimeout(() => {
                    router("/");
                }, 2000);
            } else {
                ErrorAlert(res.data.errorMessage);
            }
        }).catch(err => {
            console.log(err)
            ErrorAlert(err?.message)
        })

    }

    const createPaymentIntent = async (price) => {
        if (price > 0) {
            setStripeLoading(true);
            await axios.post(`${backendURL}/orders/create-payment-intent`, { totalPrice: price }, {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then((res) => {
                setClientSecret(res.data.clientSecret);
                setStripeLoading(false)
            }).catch(err => {
                setStripeLoading(false);
                console.log(err)
                ErrorAlert(err?.message);
            })
        } else {
            // router.push("/cart");
        }
    }

    useEffect(() => {
        if (data && data["Tu correo electrónico"]) {
            createPaymentIntent(totalPrice);
        } else {
            router("/plans")
        }

        return () => {

        }
    }, []);

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    }

    return (
        <>
            <ToastContainer />
            {
                showModal &&
                <DoneModal showModal={showModal} />
            }
            <div className="PaymentPage">
                <Header text="Pago" />
                <div className="PaymentPageContainer">
                    <div className="inner">
                        <h2 className="text-[28px]">Plan nutricional para tu mascota <b>€{totalPrice}</b></h2>
                        {
                            stripeLoading ?
                                <Loading />
                                :
                                clientSecret &&
                                (
                                    <div>
                                        <Elements options={options} stripe={stripePromise}>
                                            <StripeForm totalPrice={totalPrice} placeOrder={transactionSuccess} />
                                        </Elements>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentPage;